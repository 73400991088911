import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  COLORS,
  MARGINS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";


const ImageSVG = styled.i`
  display: inline-block;
  height: ${ props => props.height };
  width: calc(2.586*${ props => props.height });
  margin: ${ props => props.margin };
  mask-size: cover;
  mask-image: url(${ props => props.image });
  background: ${ props => props.backgroundColor };
  transition: 0.2s ease-in-out;
`;

const Button = styled.button`
  grid-area: ${ props => props.gridArea };
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: ${ props => props.textColor };
  cursor: pointer;

  &:active {
    color: ${ props => props.textColor };
  }

  &:hover ${ ImageSVG } {
    margin: ${ props => props.iconHoverMargin };
  }

  &:visited {
    color: ${ props => props.textColor };
  }
`;

const LinkText = styled.h6`
  margin: 0;
  letter-spacing: 1px;
`;


const ButtonSubmit = ({
  gridArea
}) => {
  return (
    <Button
      type="submit"
      gridArea={ gridArea }
      iconHoverMargin="0 0 0 1rem"
      textColor="#D4A380"
    >
      <LinkText>Submit</LinkText>
      <ImageSVG
        image="/icons/Arrow.svg"
        height="10px"
        margin="0 0.5rem"
        backgroundColor="#D4A380"
      />
    </Button>
  )
}


export default ButtonSubmit;