import React from "react";
import styled from "styled-components";

import {
  SCREENS
} from "../../../utils/constants";

import ButtonPrimary from "../../Button/ButtonPrimary";


const Consultation = styled.div`
  width: 200px;
  margin-top: 2rem;
  grid-area: ${ props => props.gridArea };

  @media (min-width: ${ SCREENS.laptop }) {
    margin-top: 0;
  }
`;

const ColumnHeading = styled.h6`
  margin-top: 0;
  margin-bottom: 1rem;
`;

const ConsultationDescription = styled.p`
  margin: 1rem 0;
`;


const ConsultationColumn = ({
  slice
}) => {
  return (
    <Consultation
      gridArea={ slice.primary.grid_area_name.text }
    >
      <ColumnHeading>
        { slice.primary.column_heading.text }
      </ColumnHeading>
      <ConsultationDescription>
        { slice.primary.column_details.text }
      </ConsultationDescription>
      <ButtonPrimary
        linkText={ slice.primary.button_text.text }
        linkDestination={ slice.primary.button_link }
      />
    </Consultation>
  )
}


export default ConsultationColumn;
