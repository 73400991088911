import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
`;

const SectionDescription = styled.p`
  margin: 1rem 0;

  @media (min-width: ${ SCREENS.laptop }) {
    width: 80%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 70%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 60%;
  }
`;

const ProcessContainer = styled.div`
  position: relative;
  margin-top: 4rem;
`;

const Scroller = styled.div`
  width: 100%;
  padding: 0 0 3rem;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  scroll-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }
`;

const Scrollbar = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 0.25rem;
  background-color: ${ COLORS.secondary.o3 };

  @media (min-width: ${ SCREENS.laptop }) {
    display: none;
  }
`;

const ScrollbarThumb = styled.div`
  position: absolute;
  left: 0;
  width: 1.5rem;
  height: 0.25rem;
  opacity: 1;
  background-color: ${ COLORS.secondary.o10 };
`;

const Process = styled.div`
  display: inline-block;
  width: 260px;
  margin-right: 7vw;
  white-space: normal;
  vertical-align: top;
  color: ${ props => props.textColor };

  &:last-child {
    margin-right: 0;
  }
  
  @media (min-width: ${ SCREENS.laptop }) {
    width: 19%;
    margin-right: 0;
  }
`;

const ProcessIcon = styled(GatsbyImage)`
  height: 3rem;
  width: 3rem;
`;

const ProcessTitle = styled.h6`
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid ${ props => props.iconColor };
`;

const ProcessDescription = styled.p`
  margin: 0;
  padding-top: 1rem;
`;


const SCROLL_BOX_MIN_WIDTH = 20;


const DesignProcess = ({
  slice
}) => {
  const [scrollBoxWidth, setScrollBoxWidth] = useState(SCROLL_BOX_MIN_WIDTH);
  const [scrollBoxLeft, setScrollBoxLeft] = useState(0);
  const [scrollbarThumbPos, setScrollbarThumbPos] = useState(0);
  const [isDragging, setDragging] = useState(false);

  const handleDocumentMouseUp = useCallback((e) => {
    if (isDragging) {
      e.preventDefault();
      setDragging(false);
    }
  }, [isDragging]);

  const handleDocumentMouseMove = useCallback((e) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropogation();

      const scrollHostElement = scrollHostRef.current;
      const { scrollWidth, offsetWidth } = scrollHostElement;

      let deltaX = e.clientX - scrollbarThumbPos;
      let percentage = deltaX * (scrollWidth / offsetWidth);

      setScrollbarThumbPos(e.clientX);
      setScrollBoxLeft(
        Math.min(
          Math.max(0, scrollBoxLeft + deltaX),
          offsetWidth - scrollBoxWidth
        )
      );

      scrollHostElement.scrollLeft = Math.min(
        scrollHostElement.scrollLeft + percentage,
        scrollWidth - offsetWidth
      );
    }
  }, [isDragging, scrollbarThumbPos, scrollBoxWidth, scrollBoxLeft]);

  const handleScrollThumbMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropogation();

    setScrollbarThumbPos(e.clientX);
    setDragging(true);
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) {
      return;
    }

    const scrollHostElement = scrollHostRef.current;
    const { scrollLeft, scrollWidth, offsetWidth } = scrollHostElement;

    let newLeft = (parseInt(scrollLeft, 10) / parseInt(scrollWidth, 10)) * offsetWidth;

    newLeft = Math.min(newLeft, offsetWidth - scrollBoxLeft);

    setScrollBoxLeft(newLeft);
  }, []);

  const scrollHostRef = useRef();

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    const { clientWidth, scrollWidth } = scrollHostElement;
    const scrollThumbPercentage = clientWidth / scrollWidth;
    const scrollThumbWidth = Math.max(
      scrollThumbPercentage * clientWidth,
      SCROLL_BOX_MIN_WIDTH
    );

    setScrollBoxWidth(scrollThumbWidth);

    scrollHostElement.addEventListener("scroll", handleScroll, true);

    return function cleanup() {
      scrollHostElement.removeEventListener("scroll", handleScroll, true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleDocumentMouseMove);
    document.addEventListener("mouseup", handleDocumentMouseUp);
    document.addEventListener("mouseleave", handleDocumentMouseUp);

    return function cleanup() {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.removeEventListener("mouseleave", handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  const processItems = slice.items.map(item => {
    return (
      <Process
        textColor={ slice.primary.text_color }
      >
        <ProcessIcon
          image={ getImage(item.process_stage_icon.localFile) }
        />
        <ProcessTitle
          iconColor={ slice.primary.icon_color }
        >
          { item.process_stage_title.text }
        </ProcessTitle>
        <ProcessDescription>{ item.process_stage_description.text }</ProcessDescription>
      </Process>
    )
  });

  return (
    <Section
      backgroundColor={ slice.primary.background_color }
      textColor={ slice.primary.text_color }
    >
      <Container>
        <SectionTitle>{ slice.primary.section_title.text }</SectionTitle>
        <SectionDescription>{ slice.primary.section_description.text }</SectionDescription>
        <ProcessContainer>
          <Scroller
            ref={ scrollHostRef }
          >
            { processItems }
          </Scroller>
          <Scrollbar>
            <ScrollbarThumb
              style={{ width: scrollBoxWidth, left: scrollBoxLeft }}
              onMouseDown={ handleScrollThumbMouseDown }
            />
          </Scrollbar>
        </ProcessContainer>
      </Container>
    </Section>
  )
}


export default DesignProcess;
