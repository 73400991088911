import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";

import ButtonBare from "../Button/ButtonBare";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
`;

const SectionDescription = styled.p`
  margin: 1rem 0 2rem;

  @media (min-width: ${ SCREENS.laptop }) {
    width: 80%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 70%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 60%;
  }
`;

const RelatedProductsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 0;
`;

const RelatedProduct = styled.div`
  position: relative;
  width: 100%;
  height: 468px;
  margin: 0;
  color: #FFFFFF;
`;

const RelatedProductImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const RelatedProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    ${ COLORS.primary.o6 },
    ${ COLORS.primary.o6 }
  );
`;

const RelatedProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  width: 90%;
`;

const RelatedProductDetailsHeading = styled.div``;

const RelatedProductTitle = styled.h3`
  margin: 0;
`;

const ProductTitle = styled.h3`
  margin: 0;
`;


const RelatedProductsGrid = ({
  slice
}) => {
  return (
    <Section
      backgroundColor={ slice.primary.background_color }
      textColor={ slice.primary.text_color }
    >
      <Container>
        <SectionTitle>{ slice.primary.section_title.text }</SectionTitle>
        <SectionDescription>{ slice.primary.section_description.text }</SectionDescription>
        <RelatedProductsContainer>
          { slice.items.map(item => (
            <RelatedProduct>
              <RelatedProductImage
                image={ getImage(item.related_product.document.data.related_product_image.localFile) }
              />
              <RelatedProductWrapper>
                <RelatedProductDetails>
                  <RelatedProductDetailsHeading>
                    <RelatedProductTitle>{ item.related_product.document.data.product_related_title.text }</RelatedProductTitle>
                    <ProductTitle>{ item.related_product.document.data.product_title.text }</ProductTitle>
                  </RelatedProductDetailsHeading>
                  <ButtonBare
                    linkDestination={ item.related_product }
                    textColor={ `#ffffff` }
                    buttonMargin="0"
                  />
                </RelatedProductDetails>
              </RelatedProductWrapper>
            </RelatedProduct>
          ))}
        </RelatedProductsContainer>
      </Container>
    </Section>
  )
}


export default RelatedProductsGrid;