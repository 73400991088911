import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";

import ButtonBare from "../Button/ButtonBare";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 0 0 3rem;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 0 0 4rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 0 0 5rem;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
  }
`;

const ProductVariant = styled.div``;

const ProductVariantImageContainer = styled.div`
  width: 100%;
`;

const ProductVariantImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const ProductVariantInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 13rem;

  @media (min-width: ${ SCREENS.tablet }) {
    height: 10rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    height: 10rem;
  }
`;

const ProductVariantDetails = styled.div``;

const ProductVariantTitle = styled.h2`
  margin: 1rem 0;
`;

const ProductVariantDescription = styled.p`
  margin: 1rem 0 0;
`;

const ProductVariantLinks = styled.div``;


const ProductVariantGrid = ({
  slice
}) => {
  return (
    <Section
      backgroundColor={ `#FFFFFF` }
      textColor={ slice.primary.text_color }
    >
      <Container>
        { slice.items.map(item => (
          <ProductVariant>
            <ProductVariantImageContainer>
              <ProductVariantImage
                image={ getImage(item.product_variant_image.localFile) }
              />
            </ProductVariantImageContainer>
            <ProductVariantInfoContainer>
              <ProductVariantDetails>
                <ProductVariantTitle>{ item.product_variant_title.text }</ProductVariantTitle>
                <ProductVariantDescription>{ item.product_variant_description.text }</ProductVariantDescription>
              </ProductVariantDetails>
              <ProductVariantLinks>
                <ButtonBare
                  linkText={ slice.primary.schedule_link_text.text }
                  linkDestination={ slice.primary.schedule_link }
                  textColor={ slice.primary.text_color }
                  buttonMargin="1rem 0 0"
                />
              </ProductVariantLinks>
            </ProductVariantInfoContainer>
          </ProductVariant>
        ))}
      </Container>
    </Section>
  )
}


export default ProductVariantGrid;
