import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  CONTAINERS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";


const BurgerWrapper = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  outline: none;

  @media (min-width: ${ SCREENS.laptop }) {
    display: none;
  }
`;

const BurgerIcon = styled.div`
  width: ${ SVGS.burger.width };
  height: auto;
`;

const ImageSVG = styled.img`
  height: 100%;
`;

const MenuWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: ${ props => props.navOpen ? '0' : '-100vw' };
  width: 100vw;
  height: 100vh;
  background-color: #102619;
  transition: all 0.3s ease-in-out;
  z-index: 15;
`;

const MenuHeader = styled.div`
  width: 100%;
`;

const MenuHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: ${ SVGS.brandLogo.height };
  padding: ${ PADDINGS.header.mobile.y } ${ PADDINGS.header.mobile.x };

  @media (min-width: ${ SCREENS.tablet }) {
    padding: ${ PADDINGS.header.tablet.y } ${ PADDINGS.header.tablet.x };
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  height: 80%;
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
  }
`;

const CloseButton = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  z-index: 50;

  @media (min-width: ${ SCREENS.laptop }) {
    display: none;
  }
`;

const CloseIcon = styled.div`
  width: auto;
  height: ${ SVGS.close.height };;
`;

const Navigation = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavigationItem = styled.li`
  margin: 0.5rem 0;
  font-size: 1.25rem;
`;

const NavigationItemLink = styled(Link)`
  color: ${ props => props.textColor };
  text-decoration: none;

  &:active {
    color: ${ props => props.textColor };
    text-decoration: none;
  }

  &:visited {
    color: ${ props => props.textColor };
    text-decoration: none;
  }
`;

const ContactInfo = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ContactItem = styled.li`
  margin: 0.5rem 0;
  color: ${ props => props.textColor };
  font-size: ${ props => props.fontSize ? props.fontSize : "1rem" };
`;

const ContactItemLink = styled.a`
  color: ${ props => props.textColor };
  text-decoration: none;

  &:active {
    color: ${ props => props.textColor };
    text-decoration: none;
  }

  &:visited {
    color: ${ props => props.textColor };
    text-decoration: none;
  }
`;


const Burger = ({
  navItems,
  phone
}) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleMenu = () => {
    setNavOpen(!navOpen);
  }

  return (
    <BurgerWrapper
      onClick={ toggleMenu }
    >
      <BurgerIcon>
        <ImageSVG
          src={ '/icons/Burger.svg' }
        />
      </BurgerIcon>
      <MenuWrapper
        navOpen={ navOpen }
      >
        <MenuHeader>
          <MenuHeaderContainer>
            <CloseButton
              onClick={ toggleMenu }
            >
              <CloseIcon>
                <ImageSVG
                  src={ '/icons/Close.svg' }
                />
              </CloseIcon>
            </CloseButton>
          </MenuHeaderContainer>
        </MenuHeader>
        <MenuContainer>
          <Navigation>
            { navItems.map(item => (
              <NavigationItem>
                <NavigationItemLink
                  to={ item.nav_item_link.url }
                  textColor="#FFFFFF"
                >
                  { item.nav_item_text.text }
                </NavigationItemLink>
              </NavigationItem>
            ))}
          </Navigation>
          <ContactInfo>
            <ContactItem
              fontSize="1.5rem"
              textColor="#D4A380"
            >
              Call Us
            </ContactItem>
            <ContactItem>
              <ContactItemLink
                href={ phone.link.url }
                textColor="#FFFFFF"
              >
                { phone.number.text }
              </ContactItemLink>
            </ContactItem>
          </ContactInfo>
        </MenuContainer>
      </MenuWrapper>
    </BurgerWrapper>
  )
}


export default Burger;
