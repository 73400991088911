import React from "react";
import { Link } from "gatsby";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";

import ButtonSubmit from "../Button/ButtonSubmit";
import CheckboxField from "../FormField/CheckboxField";
import OhNoHoneyField from "../FormField/OhNoHoneyField";
import SelectField from "../FormField/SelectField";
import TextField from "../FormField/TextField";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
`;

const SectionDescription = styled.p`
  margin: 1rem 0 3rem;

  @media (min-width: ${ SCREENS.laptop }) {
    width: 80%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 70%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 60%;
  }
`;

const FormContainer = styled.div``;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "name"
    "email"
    "nhsdhfvd"
    "phjbsdfh"
    "emjansdf"
    "mdknfken"
    "tkbhsdfk"
    "akhshbhbe"
    "submit";
  row-gap: 2rem;
  width: 100%;

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "name name email"
      "nhsdhfvd phjbsdfh emjansdf"
      "mdknfken mdknfken tkbhsdfk"
      "akhshbhbe akhshbhbe akhshbhbe"
      "submit submit submit";
    column-gap: 1rem;
    width: 80%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 80%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    column-gap: 2rem;
    width: 70%;
  }
`;

const LegalLink = styled(Link)`
  text-decoration: underline;
`;

const ResponseMessage = styled.p`
  margin-top: 1rem;
  color: ${ props => props.textColor };
`;


const twilioMailFunctionURL = 'https://firebrick-wolverine-2922.twil.io/contact';


const ContactForm = ({
  slice
}) => {
  return (
    <Section
      backgroundColor={ slice.primary.background_color }
      textColor={ slice.primary.text_color }
    >
      <Container>
        { slice.primary.section_title.text !== "" &&
          <>
            <SectionTitle>{ slice.primary.section_title.text }</SectionTitle>
            <SectionDescription>{ slice.primary.section_description.text }</SectionDescription>
          </>
        }
        <FormContainer>
          <Formik
            initialValues={{
              nhsdhfvd: '',
              emjansdf: '',
              phjbsdfh: '',
              mdknfken: '',
              tkbhsdfk: '',
              name: '',
              email: '',
              akhshbhbe: false
            }}
            validationSchema={Yup.object({
              nhsdhfvd: Yup.string()
                .required('Required'),
              emjansdf: Yup.string()
                .required('Required')
                .email('Invalid email address.'),
              phjbsdfh: Yup.string()
                .required('Required'),
              mdknfken: Yup.string()
                .required('Required'),
              tkbhsdfk: Yup.string()
                .required('Required'),
              name: Yup.string(),
              email: Yup.string(),
              akhshbhbe: Yup.boolean()
                .required('Required')
                .oneOf([true], 'You must accept the Terms of Use and Privacy Policy.')
            })}
            onSubmit={async (e, { resetForm, setStatus }) => {
              const {
                nhsdhfvd,
                emjansdf,
                phjbsdfh,
                mdknfken,
                tkbhsdfk,
                name,
                email,
                akhshbhbe
              } = e;
    
              const response = await fetch(twilioMailFunctionURL, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                body: new URLSearchParams({
                  nhsdhfvd,
                  emjansdf,
                  phjbsdfh,
                  tkbhsdfk,
                  mdknfken,
                  name,
                  email,
                  akhshbhbe
                }),
              });
    
              resetForm();
    
              if (response.status === 200) {
                gtag('event', 'submit', {
                  'event_category': 'form'
                });
                
                setStatus({
                  success: true
                });
              } else {
                setStatus({
                  success: false
                });
              }
            }}
          >
            { props => (
              <Form>
                <FormGrid>
                  <OhNoHoneyField
                    label="Name"
                    gridArea="name"
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                  />
                  <OhNoHoneyField
                    label="Email"
                    gridArea="email"
                    name="email"
                    type="email"
                    placeholder="Enter your name"
                  />
                  <TextField
                    label="Name"
                    gridArea="nhsdhfvd"
                    name="nhsdhfvd"
                    type="text"
                    placeholder="Enter your name"
                  />
                  <TextField
                    label="Phone"
                    gridArea="phjbsdfh"
                    name="phjbsdfh"
                    type="tel"
                    placeholder="Enter your phone number"
                  />
                  <TextField
                    label="Email"
                    gridArea="emjansdf"
                    name="emjansdf"
                    type="email"
                    placeholder="Enter your email address"
                  />
                  <TextField
                    label="Your Message"
                    gridArea="mdknfken"
                    name="mdknfken"
                    type="text"
                    placeholder="Enter your message"
                  />
                  <SelectField
                    label="When shall we contact you?"
                    gridArea="tkbhsdfk"
                    options={[
                      {
                        value: "",
                        label: "Select an option you prefer most"
                      },
                      {
                        value: "morning",
                        label: "Morning",
                      },
                      {
                        value: "afternoon",
                        label: "Afternoon",
                      },
                      {
                        value: "evening",
                        label: "Evening",
                      },
                    ]}
                    name="tkbhsdfk"
                    type="text"
                    placeholder="Enter your message"
                  />
                  <CheckboxField
                    gridArea="akhshbhbe"
                    name="akhshbhbe"
                    type="checkbox"
                  >
                    I accept the <LegalLink to="/legal/terms-of-use">Terms of Use</LegalLink> and <LegalLink to="/legal/privacy-policy">Privacy Policy</LegalLink>.
                  </CheckboxField>
                  <ButtonSubmit
                    gridArea="submit"
                  />
                </FormGrid>
                { props.status &&
                  <>
                    { props.status.success &&
                      <ResponseMessage
                        textColor="#26702E"
                      >
                        Thank you for your submission, we'll get back to you as soon as we can.
                      </ResponseMessage>
                    }
                    { !props.status.success &&
                      <ResponseMessage
                        textColor="#991B1B"
                      >
                        Something went wrong. Please contact us directly at <a href="mailto:info@uniqueblinds.ca">info@uniqueblinds.ca</a>.
                      </ResponseMessage>
                    }
                  </>
                }
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </Section>
  )
}


export default ContactForm;
