import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../../utils/constants";


const Column = styled.div`
  margin-top: 2rem;
  grid-area: ${ props => props.gridArea };

  @media (min-width: ${ SCREENS.laptop }) {
    margin-top: 0;
  }
`;



const BlankColumn = ({
  slice
}) => {
  return (
    <Column
      gridArea={ slice.primary.grid_area_name.text }
    >
    </Column>
  )
}


export default BlankColumn;
