import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import styled from "styled-components";

import {
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";

import Burger from "./Burger";


const HeaderWrapper = styled.header`
  position: fixed;
  top: ${ props => props.headerTop };
  left: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 50;
`;

const HeaderWrapperBackground = styled.div`
  position: relative;
  display: block;
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  transition: all 0.3s ease-in-out;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${ PADDINGS.header.mobile.y } ${ PADDINGS.header.mobile.x };

  @media (min-width: ${ SCREENS.tablet }) {
    padding: ${ PADDINGS.header.tablet.y } ${ PADDINGS.header.tablet.x };
  }

  @media (min-width: ${ SCREENS.laptop }) {
    padding: ${ PADDINGS.header.laptop.y } ${ PADDINGS.header.laptop.x };
  }

  @media (min-width: ${ SCREENS.desktop }) {
    padding: ${ PADDINGS.header.desktop.y } ${ PADDINGS.header.desktop.x };
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    padding: ${ PADDINGS.header.largeDesktop.y } ${ PADDINGS.header.largeDesktop.x };
  }
`;

const BrandIconLink = styled(Link)`
  display: block;

  @media (min-width: ${ SCREENS.laptop }) {
    display: none;
  }
`;

const BrandLogoLink = styled(Link)`
  display: none;

  @media (min-width: ${ SCREENS.laptop }) {
    display: block;
  }
`;

const Brand = styled.div`
  height: ${ SVGS.brandLogo.height };
  width: auto;
`;

const HeaderNav = styled.nav`
  display: none;

  @media (min-width: ${ SCREENS.laptop }) {
    display: block;
  }
`;

const Navigation = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const NavigationItem = styled.li`
  margin: 1rem;
`;

const NavigationItemLink = styled(Link)`
  color: ${ props => props.textColor };
  text-decoration: none;

  &:active {
    color: ${ props => props.textColor };
    text-decoration: none;
  }

  &:visited {
    color: ${ props => props.textColor };
    text-decoration: none;
  }
`;

const ContactLinkMobile = styled.button`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  height: ${ SVGS.phone.height };
  width: ${ SVGS.phone.height };
  background-color: transparent;
  border: none;
  outline: none;

  @media (min-width: ${ SCREENS.laptop }) {
    display: none;
  }
`;

const ContactLinkDesktop = styled.div`
  display: none;

  @media (min-width: ${ SCREENS.laptop }) {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${ SVGS.phone.height };
    width: auto;
    background-color: transparent;
    border: none;
    outline: none;
  }
`;

const PhoneNumber = styled.p`
  color: ${ props => props.textColor };

  @media (min-width: ${ SCREENS.laptop }) {
    padding-left: 16px;
  }
`;

const PhoneNumberLink = styled.a`
  color: ${ props => props.textColor };

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const ImageSVG = styled.img`
  height: 100%;
`;

const DesktopImageSVG = styled.img`
  height: 100%;
`;


const Header = ({
  isLandingPage,
  headerTextColor
}) => {
  const data = useStaticQuery(graphql`
    query Header {
      allPrismicGlobal {
        edges {
          node {
            data {
              header_nav {
                nav_item_text {
                  text
                }
                nav_item_link {
                  url
                  link_type
                }
              }
              header_phone_number {
                text
              }
              phone_number_link {
                url
                link_type
              }
            }
          }
        }
      }
    }
  `);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [pageTop, setPageTop] = useState(true);
  const [textColor, setTextColor] = useState(headerTextColor);
  const [headerVisible, setHeaderVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setPageTop(currentScrollPos < 100);
    setHeaderVisible((prevScrollPos > currentScrollPos) || currentScrollPos < 10);

    if (!isLandingPage) {
      if (pageTop) {
        setTextColor('#231F20');
      } else {
        setTextColor("#FFFFFF");
      }
    }

    setPrevScrollPos(currentScrollPos);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, pageTop, headerVisible, textColor, handleScroll]);

  if (!data) return null;

  const document = data.allPrismicGlobal.edges[0].node.data;

  const headerContent = {
    nav: document.header_nav,
    phone: {
      number: document.header_phone_number,
      link: document.phone_number_link,
    },
  };

  return (
    <>
      <HeaderWrapper
        headerTop={ headerVisible ? '0' : '-200px' }
      >
        <HeaderWrapperBackground
          backgroundColor={ pageTop ? 'transparent' : '#102619CC' }
        >
          <Container>
            <BrandLogoLink
              to="/"
            >
              <Brand>
                <ImageSVG
                  src={ '/branding/Unique-Logo.svg' }
                />
              </Brand>
            </BrandLogoLink>
            <BrandIconLink
              to="/"
            >
              <Brand>
                <ImageSVG
                  src={ '/branding/Brand-Icon.svg' }
                />
              </Brand>
            </BrandIconLink>
            <HeaderNav>
              <Navigation>
                { headerContent.nav.map(item => (
                  <NavigationItem>
                    <NavigationItemLink
                      to={ item.nav_item_link.url }
                      textColor={ textColor }
                    >
                      { item.nav_item_text.text }
                    </NavigationItemLink>
                  </NavigationItem>
                ))}
              </Navigation>
            </HeaderNav>
            <ContactLinkDesktop>
              <DesktopImageSVG
                src={ '/icons/Phone.svg' }
              />
              <PhoneNumber
                textColor={ textColor }
              >
                <PhoneNumberLink
                  href={ headerContent.phone.link.url }
                  textColor={ textColor }
                >
                  { headerContent.phone.number.text }
                </PhoneNumberLink>
              </PhoneNumber>
            </ContactLinkDesktop>
            <Burger 
              navItems={ headerContent.nav }
              phone={ headerContent.phone }
            />
          </Container>
        </HeaderWrapperBackground>
      </HeaderWrapper>
    </>
  )
}


export default Header;
