import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";

import SliceZone from "../SliceZone/SliceZone";


const FooterWrapper = styled.footer`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ COLORS.black.o10 };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const FooterLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "site social"
    "contact blank"
    "consultation blank";
  width: 100%;

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "site social contact blank consultation";
  }
`;

const Copyright = styled.p`
  margin-top: 3rem;
  margin-bottom: 0;
  text-align: center;
`;


const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query Footer {
        allPrismicGlobal {
          edges {
            node {
              data {
                copyright {
                  text
                }
                developed_by_text {
                  text
                }
                developed_by_link {
                  link_type
                  url
                }
                body {
                  ... on PrismicGlobalBodyLinksColumn {
                    primary {
                      column_heading {
                        text
                      }
                      grid_area_name {
                        text
                      }
                    }
                    items {
                      nav_item_text {
                        text
                      }
                      nav_item_link {
                        url
                        link_type
                      }
                    }
                    slice_type
                  }
                  ... on PrismicGlobalBodyContactColumn {
                    items {
                      contact_text {
                        text
                      }
                      contact_link {
                        url
                        link_type
                      }
                    }
                    primary {
                      column_heading {
                        text
                      }
                      grid_area_name {
                        text
                      }
                    }
                    slice_type
                  }
                  ... on PrismicGlobalBodyConsultationColumn {
                    primary {
                      column_heading {
                        text
                      }
                      column_details {
                        text
                      }
                      button_text {
                        text
                      }
                      button_link {
                        url
                        link_type
                      }
                      grid_area_name {
                        text
                      }
                    }
                    slice_type
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const document = data.allPrismicGlobal.edges[0].node.data;

  return (
    <FooterWrapper
      backgroundColor={ `#F4F1EF` }
    >
      <Container>
        <FooterLayout>
          <SliceZone sliceZone={ document.body } />
        </FooterLayout>
        <Copyright>
          { document.copyright.text } <a href={ document.developed_by_link.url } target="_blank" rel="noopener noreferrer">{ document.developed_by_text.text }</a>
        </Copyright>
      </Container>
    </FooterWrapper>
  )
}


export default Footer;
