import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../../utils/constants";


const Column = styled.div`
  margin-top: 2rem;
  grid-area: ${ props => props.gridArea };

  @media (min-width: ${ SCREENS.laptop }) {
    margin-top: 0;
  }
`;

const ColumnHeading = styled.h6`
  margin-top: 0;
  margin-bottom: 1rem;
`;

const NavigationWrapper = styled.nav`

`;

const Navigation = styled.ul`
  list-style-type: none;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
`;

const NavigationItem = styled.li``;

const NavigationItemLinkInt = styled(Link)``;

const NavigationItemLinkExt = styled.a``;


const LinksColumn = ({
  slice
}) => {
  return (
    <Column
      gridArea={ slice.primary.grid_area_name.text }
    >
      <ColumnHeading>
        { slice.primary.column_heading.text }
      </ColumnHeading>
      <NavigationWrapper>
        <Navigation>
          { slice.items.map(item => (
            <NavigationItem>
              { item.nav_item_link.link_type === "Document" &&
                <NavigationItemLinkInt
                  to={ item.nav_item_link.url }
                >
                  { item.nav_item_text.text }
                </NavigationItemLinkInt>
              }
              { item.nav_item_link.link_type === "Web" &&
                <NavigationItemLinkExt
                  href={ item.nav_item_link.url }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  { item.nav_item_text.text }
                </NavigationItemLinkExt>
              }
            </NavigationItem>
          ))}
        </Navigation>
      </NavigationWrapper>
    </Column>
  )
}


export default LinksColumn;
