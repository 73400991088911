import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

import {
  SCREENS
} from "../../utils/constants";

import ButtonBare from "../Button/ButtonBare";


const Section = styled.section`
  width: 100%;
  color: ${ props => props.textColor };
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ SCREENS.laptop }) {
    flex-direction: row;
  }
`;

const GridCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${ props => props.overlayColor + props.overlayColorOpacity };
  transition: all 0.3s ease-in-out;
`;

const GridItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  @media (min-width: ${ SCREENS.desktop }) {
    justify-content: space-between;
    width: 70%;
    height: 80%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    height: 50%;
  }
`;

const GridHoverItemsWrapper = styled.div`
  display: none;

  @media (min-width: ${ SCREENS.desktop }) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const GridHoverItems = styled.div`
  text-align: left;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out;

  @media (min-width: ${ SCREENS.desktop }) {
    display: block;
  }
`;

const ProductVariants = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0 0 2rem;
  padding-left: 1rem;
  border-left: 1px solid ${ props => props.borderColor };
  color: #FFFFFF;
`;

const ProductVariantsItem = styled.li`
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
`;

const GridItem = styled.div`
  position: relative;
  width: 100%;
  height: 16rem;

  @media (min-width: ${ SCREENS.laptop }) {
    width: calc(100vw / ${ props => props.numCategories });
    height: calc(100vw / ${ props => props.numCategories } * 1.353);

    &:hover ${ GridCover } {
      background-color: #102619DD;
    }
  }

  @media (min-width: ${ SCREENS.desktop }) {
    &:hover ${ GridHoverItems } {
      transform: scaleY(1);
    }
  }
`;

const GridImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const GridCoverLink = styled(Link)`
  color: ${ props => props.textColor } !important;
`;

const ProductTitle = styled.h2`
  text-align: center;
`;


const ProductGallery = ({
  slice
}) => {
  return (
    <Section
      textColor={ slice.primary.text_color }
    >
      <Grid>
        { slice.items.map(item => (
          <GridItem
            numCategories={ slice.items.length }
          >
            <GridImage
              image={ getImage(item.product_link.document.data.product_image.localFile) }
            />
            <GridCover
              overlayColor={ item.overlay_color }
              overlayColorOpacity="99"
            >
              <GridItemContentWrapper>
                <GridCoverLink
                  to={ item.product_link.url }
                  textColor={ slice.primary.text_color }
                >
                  <ProductTitle
                    textColor={ slice.primary.text_color }
                  >
                    { item.product_link.document.data.product_title.text }
                  </ProductTitle>
                </GridCoverLink>
                <GridHoverItemsWrapper>
                  <GridHoverItems>
                    <ProductVariants
                      borderColor={ slice.primary.text_color }
                    >
                      { item.product_link.document.data.body[0].items.map(productVariant => (
                        <ProductVariantsItem>{ productVariant.product_variant_title.text }</ProductVariantsItem>
                      ))}
                    </ProductVariants>
                    <ButtonBare
                      linkDestination={ item.product_link }
                      textColor={ slice.primary.text_color }
                      buttonMargin="1rem 0"
                    />
                  </GridHoverItems>
                </GridHoverItemsWrapper>
              </GridItemContentWrapper>
            </GridCover>
          </GridItem>
        ))}
      </Grid>
    </Section>
  )
}


export default ProductGallery;
