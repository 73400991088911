import React from "react";
import { useField } from "formik";
import styled from "styled-components";


const Field = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1000;

  grid-area: ${ props => props.gridArea };
`;


const OhNoHoneyField = ({
  label,
  gridArea,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Field
      gridArea={ gridArea }
    >
      <label htmlFor={ props.id || props.name }>{ label }</label>
      <input { ...field } { ...props } />
      { meta.touched && meta.error ? (
        <div>{ meta.error }</div>
      ) : null}
    </Field>
  )
}

export default OhNoHoneyField;
