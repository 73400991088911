import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled, { keyframes } from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";

import ButtonBare from "../Button/ButtonBare";


const ticker = ( scrollLength ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  height: calc(100vw * 1.295);
  overflow-x: hidden;
  color: ${ props => props.textColor };

  @media (min-width: ${ SCREENS.laptop }) {
    height: calc(100vw / 2.272);
  }
`;

const Image = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    ${ COLORS.primary.o6 },
    ${ COLORS.primary.o6 }
  );
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${ CONTAINERS.mobile };
  height: 100%;
  margin: 0 auto;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
  font-size: 2em;
  letter-spacing: 2px;

  @media (min-width: ${ SCREENS.tablet }) {
    font-size: 3.5em;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: 50%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 40%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 30%;
  }
`;

const SectionSubtitle = styled.h6`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${ props => props.buttonMargin };
  color: ${ props => props.textColor };
  letter-spacing: 1px;
`;

const Benefits = styled.div`
  position: absolute;
  bottom: 0;
  left: calc((100vw - ${ CONTAINERS.mobile }) / 2);
  width: ${ CONTAINERS.mobile };
  padding: 0 0 1rem;
  color: #FFFFFF;

  @media (min-width: ${ SCREENS.tablet }) {
    left: calc((100vw - ${ CONTAINERS.tablet }) / 2);
    width: ${ CONTAINERS.tablet };
    padding: 0 0 2rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    left: calc((100vw - ${ CONTAINERS.laptop }) / 2);
    width: ${ CONTAINERS.laptop };
  }

  @media (min-width: ${ SCREENS.desktop }) {
    left: calc((100vw - ${ CONTAINERS.desktop }) / 2);
    width: ${ CONTAINERS.desktop };
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    left: calc((100vw - ${ CONTAINERS.largeDesktop }) / 2);
    width: ${ CONTAINERS.largeDesktop };
  }
`;

const TickerWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
`;

const Ticker = styled.div`
  display: flex;
  box-sizing: content-box;
  animation: ${ props => ticker( (props.itemWidth) * props.numItems ) } 40s linear infinite;
`;

const BenefitsItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 256px;
`;

const BenefitsItem = styled.p`
  width: 130px;
  text-align: center;
`;

const ContactInfo = styled.div``;

const ContactItem = styled.h3`
  margin: 1rem 0;
  text-align: center;
`;

const PhoneNumberLink = styled.a`
  color: ${ props => props.textColor };

  &:active {
    color: ${ props => props.textColor };
  }

  &:visited {
    color: ${ props => props.textColor };
  }
`;

const ContactItemLink = styled.a`
  color: ${ props => props.textColor };

  &:active {
    color: ${ props => props.textColor };
  }

  &:visited {
    color: ${ props => props.textColor };
  }
`;


const ImageHeading = ({
  slice
}) => {
  const sources = getImage(slice.primary.section_image.localFile);

  const benefitsItems = slice.items.map(item => {
    return (
      <BenefitsItemWrapper>
        <BenefitsItem>{ item.benefits_item.text }</BenefitsItem>
      </BenefitsItemWrapper>
    )
  });

  return (
    <Section
      textColor={ slice.primary.text_color }
    >
      <Image
        image={ sources }
      />
      <Wrapper>
        <Container>
          <SectionTitle>{ slice.primary.section_title.text }</SectionTitle>
          { slice.items.length > 0 &&
            <ButtonBare
              linkText={ slice.primary.section_page_link_text.text }
              linkDestination={ slice.primary.section_page_link }
              textColor={ slice.primary.text_color }
              buttonMargin="2rem 0"
            />
          }
          { slice.items.length === 0 &&
            <>
              { slice.primary.section_page_link.url !== "" &&
                <ButtonBare
                  linkText={ slice.primary.section_page_link_text.text }
                  linkDestination={ slice.primary.section_page_link }
                  textColor={ slice.primary.text_color }
                  buttonMargin="2rem 0 0"
                />
              }
              { slice.primary.section_page_link.url === "" &&
                <SectionSubtitle
                  textColor={ slice.primary.text_color }
                  buttonMargin="2rem 0 0"
                >
                  { slice.primary.section_page_link_text.text }
                </SectionSubtitle>
              }
            </>
          }
          { slice.primary.contact_number &&
            <ContactInfo>
              <ContactItem>
                <PhoneNumberLink
                  href="tel:+14162708869"
                  textColor={ slice.primary.text_color }
                >
                  { slice.primary.contact_number.text }
                </PhoneNumberLink>
              </ContactItem>
              <ContactItem>
                <ContactItemLink
                  href="mailto:info@uniqueblinds.ca"
                  textColor={ slice.primary.text_color }
                >
                  { slice.primary.contact_email.text }
                </ContactItemLink>
              </ContactItem>
              <ContactItem>{ slice.primary.contact_hours.text }</ContactItem>
            </ContactInfo>
          }
        </Container>
        { slice.items.length > 0 &&
          <Benefits>
            <TickerWrapper>
              <Ticker
                itemWidth={ 256 }
                numItems={ 5 }
              >
                { benefitsItems }
                { benefitsItems }
              </Ticker>
            </TickerWrapper>
          </Benefits>
        }
      </Wrapper>
    </Section>
  )
}


export default ImageHeading;