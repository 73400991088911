import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  COLORS,
  MARGINS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";


const Button = styled(Link)`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #102619;
  color: #D4A380;
  border-radius: 1.5rem;
  transition: all 0.2s ease-in-out;

  &:active {
    color: #D4A380;
  }

  &:hover {
    opacity: 0.7;
  }

  &:visited {
    color: #D4A380;
  }
`;


const ButtonPrimary = ({
  linkText,
  linkDestination
}) => {
  linkText = linkText === undefined ? "Learn more" : linkText;

  return (
    <Button
      to={ linkDestination.url }
    >
      { linkText }
    </Button>
  )
}


export default ButtonPrimary;