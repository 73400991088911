const COLORS = {
  primary: {
    o0: 'rgba(16, 38, 25, 0.0)',
    o1: 'rgba(16, 38, 25, 0.1)',
    o2: 'rgba(16, 38, 25, 0.2)',
    o3: 'rgba(16, 38, 25, 0.3)',
    o4: 'rgba(16, 38, 25, 0.4)',
    o5: 'rgba(16, 38, 25, 0.5)',
    o6: 'rgba(16, 38, 25, 0.6)',
    o7: 'rgba(16, 38, 25, 0.7)',
    o8: 'rgba(16, 38, 25, 0.8)',
    o9: 'rgba(16, 38, 25, 0.9)',
    o10: 'rgba(16, 38, 25, 1.0)',
  },
  secondary: {
    o0: 'rgba(212, 163, 128, 0.0)',
    o1: 'rgba(212, 163, 128, 0.1)',
    o2: 'rgba(212, 163, 128, 0.2)',
    o3: 'rgba(212, 163, 128, 0.3)',
    o4: 'rgba(212, 163, 128, 0.4)',
    o5: 'rgba(212, 163, 128, 0.5)',
    o6: 'rgba(212, 163, 128, 0.6)',
    o7: 'rgba(212, 163, 128, 0.7)',
    o8: 'rgba(212, 163, 128, 0.8)',
    o9: 'rgba(212, 163, 128, 0.9)',
    o10: 'rgba(212, 163, 128, 1.0)',
  },
  black: {
    o0: 'rgba(0, 0, 0, 0.0)',
    o1: 'rgba(0, 0, 0, 0.1)',
    o2: 'rgba(0, 0, 0, 0.2)',
    o3: 'rgba(0, 0, 0, 0.3)',
    o4: 'rgba(0, 0, 0, 0.4)',
    o5: 'rgba(0, 0, 0, 0.5)',
    o6: 'rgba(0, 0, 0, 0.6)',
    o7: 'rgba(0, 0, 0, 0.7)',
    o8: 'rgba(0, 0, 0, 0.8)',
    o9: 'rgba(0, 0, 0, 0.9)',
    o10: 'rgba(0, 0, 0, 1.0)',
  },
};

const CONTAINERS = {
  mobile: '300px',
  tablet: '568px',
  laptop: '960px',
  desktop: '1024px',
  largeDesktop: '1280px',
};

const MARGINS = {
  header: {
    x: '100px',
    y: '80px',
  },
};

const PADDINGS = {
  header: {
    mobile: {
      x: '1rem',
      y: '2rem',
    },
    tablet: {
      x: '2rem',
      y: '2rem',
    },
    laptop: {
      x: '3rem',
      y: '3rem',
    },
    desktop: {
      x: '4rem',
      y: '4rem',
    },
    largeDesktop: {
      x: '6rem',
      y: '4rem',
    },
  },
};

const SCREENS = {
  tablet: '640px',
  laptop: '1025px',
  desktop: '1280px',
  largeDesktop: '1536px',
};

const SVGS = {
  brandLogo: {
    height: '60px',
  },
  burger: {
    width: '40px',
  },
  close: {
    height: '40px',
  },
  arrow: {
    height: '10px',
  },
  phone: {
    height: '40px',
  },
}


export {
  COLORS,
  CONTAINERS,
  MARGINS,
  PADDINGS,
  SCREENS,
  SVGS
};
