import React from "react";
import styled from "styled-components";

import {
  SCREENS
} from "../../../utils/constants";


const Column = styled.div`
  margin-top: 2rem;
  grid-area = ${ props => props.gridArea };

  @media (min-width: ${ SCREENS.laptop }) {
    margin-top: 0;
  }
`;

const ColumnHeading = styled.h6`
  margin-top: 0;
  margin-bottom: 1rem;
`;

const NavigationWrapper = styled.nav`

`;

const Navigation = styled.ul`
  list-style-type: none;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
`;

const NavigationItem = styled.li``;

const NavigationItemLinkExt = styled.a``;


const ContactColumn = ({
  slice
}) => {
  return (
    <Column
      gridArea={ slice.primary.grid_area_name.text }
    >
      <ColumnHeading>
        { slice.primary.column_heading.text }
      </ColumnHeading>
      <Navigation>
        { slice.items.map(item => (
          <>
            { item.contact_link.url === "" &&
              <NavigationItem>{ item.contact_text.text }</NavigationItem>
            }
            { item.contact_link.url !== "" &&
              <NavigationItem>
                <NavigationItemLinkExt
                  href={ item.contact_link.url }
                >
                  { item.contact_text.text }
                </NavigationItemLinkExt>
              </NavigationItem>
            }
          </>
        ))}
      </Navigation>
    </Column>
  )
}


export default ContactColumn;