/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import GlobalStyles from "../../theme/globalStyles";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";


const Layout = ({
  children,
  isLandingPage,
  headerTextColor
}) => {
  return (
    <>
      <GlobalStyles />

      <main>
        { children }
      </main>

      <Footer />

      <Header
        isLandingPage={ isLandingPage }
        headerTextColor={ headerTextColor }
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
