import React from "react";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";


const Article = styled.article`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 2rem 0 3rem;
  color: #231F20;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 2rem 0 4rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 2rem 0 5rem;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    margin: 0 auto;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    margin: 0 auto;
  }
`;


const ArticleSectionText = ({
  slice
}) => {
  return (
    <Article>
      <RichText render={ slice.primary.article_section_text.raw } />
    </Article>
  )
}


export default ArticleSectionText;