import { createGlobalStyle } from "styled-components";

import {
  SCREENS
} from "../utils/constants";


const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: TitilliumWebRegular;
    src: url("/fonts/TitilliumWeb-Regular.ttf");
  }

  body {
    color: white;
    font-family: TitilliumWebRegular;
  }

  h1 {
    font-size: 2em;
    letter-spacing: 2px;

    @media (min-width: ${ SCREENS.tablet }) {
      font-size: 3.5em;
    }
  }

  h2 {
    font-size: 1.875em;
  }

  h3 {
    font-size: 1.75em;
  }

  h4 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1.25em;
  }

  h6 {
    font-size: 1em;
    font-weight: 600;
  }

  p {
    font-size: 1em;
  }

  ul, ol {
    font-size: 1em;
  }

  a {
    color: #231F20;
    text-decoration: none;

    &:active {
      color: #231F20;
      text-decoration: none;
    }

    &:visited {
      color: #231F20;
      text-decoration: none;
    }
  }
`;


export default GlobalStyles;
