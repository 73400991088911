import React from "react";
import { useField } from "formik";
import styled from "styled-components";


const Field = styled.div`
  grid-area: ${ props => props.gridArea };
`;

const FieldWrapper = styled.div`

`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border: 2px solid #231F20;
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 700;

  & ${ Input }:checked ~ ${ Checkbox } {
    background-color: #231F20;
  }

  & ${ Input }:checked ~ ${ Checkbox }:after {
    display: block;
  }

  & ${ Checkbox }:after {
    left: 0;
    top: 0;
    width: 8px;
    height: 8px;
    border: solid white;
    border-width: 4px;
    border-radius: 50%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Error = styled.div`
  margin: 0.5rem 0;
  color: #991B1B;
`;


const CheckboxField = ({
  children,
  gridArea,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Field
      gridArea={ gridArea }
    >
      <FieldWrapper>
        <Label>    
          <Input { ...field } { ...props } />
          <Checkbox></Checkbox>
          { children }
        </Label>
      </FieldWrapper>
      { meta.touched && meta.error ? (
        <Error>{ meta.error }</Error>
      ) : null}
    </Field>
  )
}

export default CheckboxField;
