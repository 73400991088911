import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useEmblaCarousel from "embla-carousel-react";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
`;

const SectionDescription = styled.p`
  margin: 1rem 0;

  @media (min-width: ${ SCREENS.laptop }) {
    width: 80%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 70%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 60%;
  }
`;

const TestimonialsContainer = styled.div`
  margin-top: 4rem;
`;

const Embla = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;

  &.is-draggable {
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`;

const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const EmblaDots = styled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 2rem 0 0;
`;

const EmblaDot = styled.button`
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: calc(100% / ${ props => props.numItems });
  height: 2rem;
  margin: 0;
  display: flex;
  align-items: center;

  &:after {
    background-color: ${ COLORS.secondary.o2 };
    width: 100%;
    height: 4px;
    content: "";
  }

  &.is-selected:after {
    background-color: ${ COLORS.secondary.o10 };
    opacity: 1;
  }
`;

const Testimonial = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  margin: 0 2.5%;
  color: ${ props => props.textColor };

  @media (min-width: ${ SCREENS.laptop }) {
    width: 30%;
  }
`;

const TestimonialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
`;

const TestimonialText = styled.p`
  margin: 0;
`;

const TestimonialWrapperBottom = styled.div``;

const TestimonialAuthor = styled.h5`
  margin: 0;
`;

const TestimonialRatingImage = styled(GatsbyImage)`
  width: 30%;
  margin-top: 0.5rem;
`;


const DotButton = ({ selected, onClick, numItems }) => {
  return (
    <EmblaDot
      className={ selected ? "is-selected" : "" }
      type="button"
      onClick={ onClick }
      numItems={ numItems }
    />
  )
}


const TestimonialSlider = ({
  slice
}) => {
  const options = {
    loop: true,
    skipSnaps: false,
  };

  const [viewportRef, embla] = useEmblaCarousel(options);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedIndex(embla.selectedScrollSnap());
    setPrevButtonEnabled(embla.canScrollPrev());
    setNextButtonEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    setScrollSnaps(embla.scrollSnapList());

    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const testimonialsSliderItems = slice.items.map(item => {
    return (
      <Testimonial>
        <TestimonialWrapper>
          <TestimonialText>{ item.testimonial_text.text }</TestimonialText>
          <TestimonialWrapperBottom>
            <TestimonialAuthor>{ item.testimonial_author.text }</TestimonialAuthor>
            <TestimonialRatingImage
              image={ getImage(item.testimonial_rating.localFile) }
            />
          </TestimonialWrapperBottom>
        </TestimonialWrapper>
      </Testimonial>
    )
  });

  return (
    <Section
      backgroundColor={ slice.primary.background_color }
      textColor={ slice.primary.text_color }
    >
      <Container>
        <SectionTitle>{ slice.primary.section_title.text }</SectionTitle>
        <SectionDescription>{ slice.primary.section_description.text }</SectionDescription>
        <TestimonialsContainer>
          <Embla>
            <Viewport
              ref={ viewportRef }
            >
              <EmblaContainer>
                { testimonialsSliderItems }
              </EmblaContainer>
            </Viewport>
          </Embla>
          <EmblaDots>
            { scrollSnaps.map((_, index) => (
              <DotButton
                key={ index }
                selected={ index === selectedIndex }
                onClick={ () => scrollTo(index) }
                numItems={ scrollSnaps.length }
              />
            ))}
          </EmblaDots>
        </TestimonialsContainer>
      </Container>
    </Section>
  )
}


export default TestimonialSlider;
