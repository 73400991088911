import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useEmblaCarousel from "embla-carousel-react";
import styled from "styled-components";

import {
  SCREENS
} from "../../utils/constants";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0 0 3rem;

  @media (min-width: ${ SCREENS.tablet }) {
    margin: 0;
    padding: 0 0 4rem;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    margin: 0;
    padding: 0 0 5rem;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    margin: 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    margin: 0;
  }
`;

const Embla = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;

  &.is-draggable {
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`;

const EmblaContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const PortfolioItem = styled.div`
  position: relative;
  flex: 0 0 auto;
  margin: 0 1rem;
  width: 250px;

  &:focus {
    outline-style: none;
    outline-width: 0;
  }

  @media (min-width: ${ SCREENS.tablet }) {
    margin: 0 2rem;
    width: 600px;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: 800px;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 1000px;
  }
`;

const PortfolioItemImage = styled(GatsbyImage)`
  width: auto;
  height: 80%;
`;

const PortfolioItemTitle = styled.h2`
  margin: 0.5rem 0;
`;

const PortfolioItemProjectArea = styled.h6`
  margin: 0;
`;


const PortfolioSlider = ({
  slice
}) => {
  const options = {
    loop: true,
  };

  const [viewportRef, embla] = useEmblaCarousel(options);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setPrevButtonEnabled(embla.canScrollPrev());
    setNextButtonEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    embla.on("select", onSelect);
    
    onSelect();
  }, [embla, onSelect]);

  const portfolioSliderItems = slice.items.map(item => {
    return (
      <PortfolioItem>
        <PortfolioItemImage
          image={ getImage(item.portfolio_client_image.localFile) }
        />
        <PortfolioItemTitle>{ item.portfolio_client_title.text }</PortfolioItemTitle>
        <PortfolioItemProjectArea>{ item.portfolio_client_project_area.text }</PortfolioItemProjectArea>
      </PortfolioItem>
    )
  });

  return (
    <Section
      textColor={ slice.primary.text_color }
    >
      <Container>
        <Embla>
          <Viewport
            ref={ viewportRef }
          >
            <EmblaContainer>
              { portfolioSliderItems }
            </EmblaContainer>
          </Viewport>
        </Embla>
      </Container>
    </Section>
  )
}


export default PortfolioSlider;
