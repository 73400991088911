import React from "react";
import { useField } from "formik";
import styled from "styled-components";


const Field = styled.div`
  grid-area: ${ props => props.gridArea };
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 4rem;
  border-bottom: 2px solid #231F20;
`;

const Label = styled.label`
  font-weight: 700;
`;

const Input = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  padding: 0.5rem 0;
  font-weight: 100;

  &:focus {
    border: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:active {
    border: none;
  }

  &::placeholder {
    color: #231F20;
  }
`;

const Error = styled.div`
  margin: 0.5rem 0;
  color: #991B1B;
`;


const TextField = ({
  label,
  gridArea,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Field
      gridArea={ gridArea }
    >
      <FieldWrapper>
        <Label
          htmlFor={ props.id || props.name }
        >
          { label }
        </Label>
        <Input { ...field } { ...props } />
      </FieldWrapper>
      { meta.touched && meta.error ? (
        <Error>{ meta.error }</Error>
      ) : null}
    </Field>
  )
}

export default TextField;