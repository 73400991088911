import React from "react";

import ArticleSectionText from "../Slice/ArticleSectionText";
import ArticlesSlider from "../Slice/ArticlesSlider";
import Benefits from "../Slice/Benefits";
import CallToAction from "../Slice/CallToAction";
import DesignProcess from "../Slice/DesignProcess";
import ContactForm from "../Slice/ContactForm";
import ImageHeading from "../Slice/ImageHeading";
import PortfolioSlider from "../Slice/PortfolioSlider";
import ProductGallery from "../Slice/ProductGallery";
import ProductVariantGrid from "../Slice/ProductVariantGrid";
import RelatedProductsGrid from "../Slice/RelatedProductsGrid";
import TestimonialSlider from "../Slice/TestimonialSlider";
import Text from "../Slice/Text";

import BlankColumn from "../Slice/Footer/BlankColumn";
import ConsultationColumn from "../Slice/Footer/ConsultationColumn";
import ContactColumn from "../Slice/Footer/ContactColumn";
import LinksColumn from "../Slice/Footer/LinksColumn";


const SliceZone = ({
  sliceZone
}) => {
  const sliceComponents = {
    article_paragraph: ArticleSectionText,
    article_list: ArticlesSlider,
    benefits: Benefits,
    blank_column: BlankColumn,
    call_to_action: CallToAction,
    consultation_column: ConsultationColumn,
    contact_column: ContactColumn,
    form: ContactForm,
    image_heading: ImageHeading,
    links_column: LinksColumn,
    process: DesignProcess,
    portfolio_slider: PortfolioSlider,
    product_gallery: ProductGallery,
    product_variant: ProductVariantGrid,
    related_products: RelatedProductsGrid,
    testimonials: TestimonialSlider,
    text: Text,
  };
  
  const sliceZoneContent = sliceZone.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];

    if (SliceComponent) {
      return <SliceComponent slice={ slice } key={ `slice-${ index }` } />
    }

    return null;
  })

  return (
    <>
      { sliceZoneContent }
    </>
  )
}


export default SliceZone;
