import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import {
  COLORS,
  MARGINS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";


const ImageSVG = styled.i`
  display: inline-block;
  height: ${ props => props.height };
  width: calc(2.586*${ props => props.height });
  margin: ${ props => props.margin };
  mask-size: cover;
  mask-image: url(${ props => props.image });
  background: ${ props => props.backgroundColor };
  transition: 0.2s ease-in-out;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${ props => props.buttonMargin };
  color: ${ props => props.textColor };

  &:hover ${ ImageSVG } {
    margin: ${ props => props.iconHoverMargin };
  }
`;

const InternalLink = styled(Link)`
  color: ${ props => props.textColor };

  &:active {
    color: ${ props => props.textColor };
  }

  &:visited {
    color: ${ props => props.textColor };
  }
`;

const ExternalLink = styled.a`
  color: ${ props => props.textColor };

  &:active {
    color: ${ props => props.textColor };
  }

  &:visited {
    color: ${ props => props.textColor };
  }
`;

const LinkText = styled.h6`
  margin: 0;
  letter-spacing: 1px;
`;


const ButtonBare = ({
  linkText,
  linkDestination,
  textColor,
  buttonMargin
}) => {
  if (linkDestination.url === "") return null;

  linkText = linkText === undefined ? "Learn more" : linkText;

  return (
    <Button
      iconHoverMargin="0 0.5rem"
      textColor={ textColor }
      buttonMargin={ buttonMargin }
    >
      <ImageSVG
        image="/icons/Arrow.svg"
        height="10px"
        margin="0 1rem 0 0"
        backgroundColor={ textColor }
      />
      { linkDestination.link_type === "Document" ?
        (
          <LinkText>
            <InternalLink
              to={ linkDestination.url }
              textColor={ textColor }
            >
              { linkText }
            </InternalLink>
          </LinkText>
        ) :
        (
          <LinkText>
            <ExternalLink
              href={ linkDestination.url }
              textColor={ textColor }
            >
              { linkText }
            </ExternalLink>
          </LinkText>
        )
      }
    </Button>
  )
}


export default ButtonBare;
