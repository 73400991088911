import React from "react";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";

import ButtonBare from "../Button/ButtonBare";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const SectionTitle = styled.h2`
  margin: 0;
`;

const SectionDescription = styled.p`
  margin: ${ props => props.margin };

  @media (min-width: ${ SCREENS.laptop }) {
    width: 80%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 70%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 60%;
  }
`;


const Text = ({
  slice
}) => {
  return (
    <Section
      backgroundColor={ slice.primary.background_color }
      textColor={ slice.primary.text_color }
    >
      { slice.primary.section_title.text &&
        <Container>
          <SectionTitle>
            { slice.primary.section_title.text }
          </SectionTitle>
          <SectionDescription
            margin="1rem 0"
          >
            { slice.primary.section_description.text }
          </SectionDescription>
          <ButtonBare
            linkText={ slice.primary.section_page_link_text.text }
            linkDestination={ slice.primary.section_page_link }
            textColor={ slice.primary.text_color }
            buttonMargin="1rem 0 0 0"
          />
        </Container>
      }
      { !slice.primary.section_title.text &&
        <Container>
          <SectionTitle>
            { slice.primary.section_title.text }
          </SectionTitle>
          <SectionDescription
            margin="0 0 1rem"
          >
            { slice.primary.section_description.text }
          </SectionDescription>
          <ButtonBare
            linkText={ slice.primary.section_page_link_text.text }
            linkDestination={ slice.primary.section_page_link }
            textColor={ slice.primary.text_color }
            buttonMargin="1rem 0 0 0"
          />
        </Container>
      }
    </Section>
  )
}


export default Text;
