import React from "react";
import styled, { keyframes } from "styled-components";

import {
  CONTAINERS,
  SCREENS
} from "../../utils/constants";


const ticker = ( scrollLength ) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-${ scrollLength }px, 0, 0);
  }
`;

const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
  color: ${ props => props.textColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 3rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
    padding: 4rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
    padding: 5rem 0;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
    padding: 5rem 0;
  }
`;

const TickerWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
`;

const Ticker = styled.div`
  display: flex;
  box-sizing: content-box;
  animation: ${ props => ticker( (props.itemWidth) * props.numItems ) } 40s linear infinite;
`;

const BenefitsItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 256px;
`;

const BenefitsItem = styled.p`
  width: 130px;
  text-align: center;
`;


const Benefits = ({
  slice
}) => {
  const benefitsItems = slice.items.map(item => {
    return (
      <BenefitsItemWrapper>
        <BenefitsItem>{ item.benefit_item.text }</BenefitsItem>
      </BenefitsItemWrapper>
    )
  });

  return (
    <Section
      backgroundColor={ slice.primary.background_color }
      textColor={ slice.primary.text_color }
    >
      <Container>
        <TickerWrapper>
          <Ticker
            itemWidth={ 256 }
            numItems={ 5 }
          >
            { benefitsItems }
            { benefitsItems }
          </Ticker>
        </TickerWrapper>
      </Container>
    </Section>
  )
}


export default Benefits;
