import React from "react";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  SCREENS
} from "../../utils/constants";


const Section = styled.section`
  width: 100%;
  background-color: ${ props => props.backgroundColor };
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  padding: 2rem 0;

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin: 0 auto;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin: 0 auto;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: ${ CONTAINERS.desktop };
    margin: 0 auto;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: ${ CONTAINERS.largeDesktop };
    margin: 0 auto;
  }
`;

const SectionTitle = styled.h5`
  margin: 0;
  color: ${ props => props.textColor };
  text-align: center;
`;

const SectionTitleLink = styled.a`
  display: block;
  padding: 1rem 0 0;
  color: ${ props => props.textColor };
`;


const CallToAction = ({
  slice
}) => {
  return (
    <Section
      backgroundColor={ slice.primary.background_color }
    >
      <Container>
        <SectionTitle
          textColor={ slice.primary.text_color }
        >
          { slice.primary.action_text.text }
        </SectionTitle>
        <SectionTitle>
          <SectionTitleLink
            href={ slice.primary.action_link.url }
            textColor={ slice.primary.text_color }
          >
            { slice.primary.action_number.text }
          </SectionTitleLink>
        </SectionTitle>
      </Container>
    </Section>
  )
}


export default CallToAction;
